import Navigation from "../сomponents/Nav"

const Blog = () => {
    return (
        <>
            <Navigation />
        </>
    )
}

export default Blog